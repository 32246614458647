import { UserHelper } from 'utils/userHelper.util';
import { useInternalStore } from 'shared/store/internalStore.store';
import { ComputedRef } from 'vue';

export function useShowPriceClient(ociMode: boolean): ComputedRef<boolean> | undefined {
  if (process.server) {
    return computed(() => !!(useInternalStore().getIsInternalUser || UserHelper.isClient || ociMode));
  }

  return computed(() => useInternalStore().getIsInternalUser || UserHelper.isClient || ociMode);
}

import { RequestHelper } from 'shared/utils/requestHelper.util';

export function useCanonicalLink(event = useRequestEvent()): void {
  useHead({
    link: [{
      rel: 'canonical',
      href: process.server
        ? getHrefOnServer()
        : getHrefOnClient(),
    }],
  });

  function getHrefOnServer(): string {
    if (!event) {
      return '';
    }

    const { origin, pathname } = RequestHelper.getRequestURL(event);
    return `${origin}${pathname}`;
  }

  function getHrefOnClient(): string {
    const { location } = window || {};
    return `${location.origin}${location.pathname}`;
  }
}
